<template>
  <el-dialog
    :title="null"
    :visible.sync="dialogVisible"
    width="30%"
    class="rating-root"
    :before-close="handleClose"
  >
    <el-steps :active="active" finish-status="success">
      <el-step title="选择评分标准"> </el-step>
      <el-step title="开始打分"></el-step>
      <el-step title="完成"></el-step>
    </el-steps>
    <div class="main-root">
      <div class="step-one" v-if="active === 0">
        <el-form>
          <el-form-item label="评分标准">
            <el-select
              v-model="ratingType"
              placeholder="请选择评分标准"
              size="mini"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="step-one" v-if="active === 1">
        <div class="form-item">
          <label for="selectedUsers">要打分的用户</label>
          <el-select
            v-model="selectedUsers"
            placeholder="选择要打分的用户"
            size="mini"
            multiple
            value-key="createdUserId"
            class="selectedUsers"
            v-if="type === 'standard'"
          >
            <el-option
              v-for="item in usersList"
              :key="item.createdUserId"
              :label="item.createdName"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="selectedUsers"
            placeholder="选择要打分的用户"
            size="mini"
            multiple
            value-key="userId"
            class="selectedUsers"
            v-else
          >
            <el-option
              v-for="item in usersList"
              :key="item.userId"
              :label="item.realName"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="form-item"
          v-for="(user, index) in selectedUsers"
          :key="user.id"
        >
          <label for="selectedUsers" v-if="type === 'standard'"
            >给{{ user.createdName }}评分</label
          >
          <label for="selectedUsers" v-else>给{{ user.realName }}评分</label>
          <el-select
            v-model="ratingData.users[index].grade"
            :placeholder="'请选择评分标准' + ratingData.origin.description"
            size="mini"
            style="width: 300px"
          >
            <el-option
              v-for="(val, index) in ratingData.record"
              :key="index"
              :label="val"
              :value="val"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="step-one" v-if="active === 2">
      <p>恭喜，完成了打分~！</p>
    </div>
    <div slot="footer">
      <div class="steps-control" v-if="active !== 2">
        <el-button style="margin-top: 12px" v-if="active !== 0" @click="prev"
          >上一步</el-button
        >
        <el-button style="margin-top: 12px" @click="next">下一步</el-button>
      </div>
      <el-button v-else style="margin-top: 12px" @click="handleClose"
        >完成</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      active: 0,
      selectedUsers: [],
      options: [
        {
          label: "10最优,1最差",
          value: 0,
        },
        {
          label: "A最优,D最差",
          value: 1,
        },
        {
          label: "A优秀,B一般,C差",
          value: 2,
        },
      ],
      ratingType: 0,
      rating: [],
      usersList: [],
      ratingData: {
        // 原始数据
        origin: null,
        // 打分
        record: [],
        // 用户
        users: [],
      },
    };
  },
  props: ["value", "type"],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    ...mapGetters(["getUserInfo"]),
  },
  watch: {
    //   选择的用户更新，
    selectedUsers(newVals) {
      if (newVals.length) {
        this.ratingData.users = newVals.map((item) => ({
          grade: "",
          gradeRemark: this.ratingData.origin.description,
          //   受评人
          rateeUserId:
            this.type === "standard" ? item.createdUserId : item.userId,
          //   打分人
          raterUserId: this.getUserInfo.id,
        }));
      } else {
        this.ratingData.users = [];
      }
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.active = 0;
    },
    prev() {
      //   第二步
      if (this.active-- < 0) {
        this.active = 2;
      }
      // 清空
      this.ratingData.origin = null;
      this.ratingData.record = [];
      this.ratingData.users = [];
      this.selectedUsers = [];
    },
    async next() {
      // 第一步
      if (this.active === 0) {
        this.fetchRatingType(this.ratingType);
      }
      // 第二步，通过返回结果控制步频
      if (this.active === 1) {
        await this.updateGrade(this.type);
        this.active++;
        return false;
      }

      // 第三步
      if (this.active++ > 2) {
        this.active = 0;
      }
    },
    reset() {
      this.active = 0;
    },
    fetchRatingType(type) {
      this.$axios({
        method: "get",
        url: `api/csp/mgr/v1/ref/type/${type}`,
      }).then((response) => {
        const data = response.data.data;

        this.ratingData.origin = data;
        this.ratingData.record = data?.val.split(",") || [];
      });
    },
    fetchUsers(type) {
      const id = this.$route.query?.id;
      let url = "";

      if (!id) {
        this.$message.error("缺少记录id！");
        return false;
      }

      switch (type) {
        //   远程
        case "remote":
          url = "api/csp/rc/v1/session/attendeeAllUsers/";
          break;
        //   任务
        case "task":
          url = "api/csp/task/v1/task/users/";
          break;
        //   标准作业
        case "standard":
          url = "api/csp/sop/v1/sopRecord/detail/";
          break;
        default:
      }
      if (!url) {
        this.$message.error("缺少url");
        return false;
      }
      this.$axios({
        method: "get",
        url: `${url}${id}`,
      }).then((response) => {
        const data = response.data.data;

        this.usersList = this.type === "standard" ? [data] : data;
      });
    },
    updateGrade(type) {
      return new Promise((resolve, reject) => {
        const id = this.$route.query?.id;
        let url = "";
        switch (type) {
          //   远程
          case "remote":
            url = "api/csp/rc/v1/session/grade/";
            break;
          //   任务
          case "task":
            url = "api/csp/task/v1/task/grade/";
            break;
          //   标准作业
          case "standard":
            url = "api/csp/sop/v1/sopRecord/grade/";
            break;
          default:
        }
        if (!url) {
          this.$message.error("缺少url");
          return false;
        }

        if (!this.ratingData.users.length) {
          this.$message.error("请选择用户！");
          reject();
          return false;
        }
        if (this.ratingData.users.every((item) => item.grade === "")) {
          this.$message.error("打分不能为空！");
          reject();
          return false;
        }
        this.$axios({
          method: "put",
          url: `${url}${id}`,
          data: { data: { grades: this.ratingData.users } },
        }).then((response) => {
          let data = response.data;

          if (!data.status) {
            this.$message.success("评分成功！");
            resolve();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
              duration: 1000,
            });
          }
        });
      });
    },
  },
  mounted() {
    this.fetchUsers(this.type);
  },
};
</script>

<style lang="scss">
.el-dialog .el-dialog__body {
  text-align: left;
}
.main-root {
  margin-top: 20px;
}
.form-item {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
  label {
    display: inline-block;
    width: 150px;
    word-break: break-all;
  }
}
</style>
