var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-tags-root" },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "20px" },
          on: {
            click: function ($event) {
              return _vm.closePopup(false)
            },
          },
        },
        [_vm._v("标签管理")]
      ),
      _c(
        "div",
        { staticClass: "tags-root" },
        _vm._l(_vm.showTags, function (tag) {
          return _c(
            "el-tag",
            {
              key: tag,
              attrs: { "disable-transitions": false },
              on: {
                close: function ($event) {
                  return _vm.handleClose(tag)
                },
              },
            },
            [_vm._v(" " + _vm._s(tag) + " ")]
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "rating-root",
          attrs: {
            title: null,
            visible: _vm.status === "create",
            width: "30%",
          },
          on: {
            close: function ($event) {
              return _vm.closePopup(true)
            },
          },
        },
        [
          _vm._l(_vm.dynamicTags, function (tag) {
            return _c(
              "el-tag",
              {
                key: tag,
                attrs: { closable: "", "disable-transitions": false },
                on: {
                  close: function ($event) {
                    return _vm.handleClose(tag)
                  },
                },
              },
              [_vm._v(" " + _vm._s(tag) + " ")]
            )
          }),
          _vm.inputVisible
            ? _c("el-input", {
                ref: "saveTagInput",
                staticClass: "input-new-tag",
                attrs: { size: "small" },
                on: { blur: _vm.handleInputConfirm },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleInputConfirm.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              })
            : _vm._e(),
          _vm.dynamicTags.length < 5 && !_vm.inputVisible
            ? _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { size: "small" },
                  on: { click: _vm.showInput },
                },
                [_vm._v("+新增标签")]
              )
            : _vm._e(),
          _c("h2", { staticClass: "node-title" }, [_vm._v("请选择节点")]),
          _c("VideoTagsManage", {
            attrs: { type: "select", parentClass: "custom" },
            on: { check: _vm.check },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closePopup(true)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }