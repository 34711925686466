<template>
  <div class="add-tags-root">
    <el-button style="margin-left: 20px" @click="closePopup(false)"
      >标签管理</el-button
    >
    <div class="tags-root">
      <el-tag
        :key="tag"
        v-for="tag in showTags"
        :disable-transitions="false"
        @close="handleClose(tag)"
      >
        {{ tag }}
      </el-tag>
    </div>
    <el-dialog
      :title="null"
      :visible="status === 'create'"
      width="30%"
      class="rating-root"
      @close="closePopup(true)"
    >
      <el-tag
        :key="tag"
        v-for="tag in dynamicTags"
        closable
        :disable-transitions="false"
        @close="handleClose(tag)"
      >
        {{ tag }}
      </el-tag>

      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button
        v-if="dynamicTags.length < 5 && !inputVisible"
        class="button-new-tag"
        size="small"
        @click="showInput"
        >+新增标签</el-button
      >
      <h2 class="node-title">请选择节点</h2>
      <VideoTagsManage type="select" parentClass="custom" @check="check" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="closePopup(true)">取 消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VideoTagsManage from "@/components/AddTags/VideoTagsManage.vue";

export default {
  props: ["id", "type", "showTags"],
  components: {
    VideoTagsManage,
  },
  data() {
    return {
      inputVisible: false,
      status: "show", // create 新建; show展示;
      dynamicTags: [], //向后台提交的数据
      inputValue: "",
    };
  },
  watch: {
    status(newV, oldV) {},
    showTags: {
      handler(newV) {
        this.dynamicTags = [...newV];
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    closePopup(close) {
      this.status = close ? "show" : "create";
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    check(nodeLabelList) {
      this.dynamicTags = [...this.dynamicTags, ...nodeLabelList.reverse()];
      this.dynamicTags.length = 5;
    },
    submit() {
      const type = this.type;
      let url = "";
      switch (type) {
        //   远程
        case "remote":
          url = "api/csp/rc/v1/session/tags/";
          break;
        //   任务
        case "task":
          url = "api/csp/task/v1/taskRecording/tags/";
          break;
        //   标准作业
        case "standard":
          url = "api/csp/sop/v1/sopRecord/tags/";
          break;
        default:
      }

      this.$axios({
        method: "put",
        url: `${url}${this.id}`,
        data: {
          data: { tags: this.dynamicTags.join(",") },
        },
      }).then(({ data }) => {
        if (data.status === 0) {
          this.closePopup(true);
          setTimeout(() => {
            this.$emit("after-edit");
          }, 1000);
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.add-tags-root {
  display: flex;
  justify-content: flex-start;
  .tags-root {
    margin-left: 20px;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .custom {
    max-height: 500px;
    overflow-y: auto;
    .submit-btns {
      display: none;
    }
  }
  .node-title {
    margin-top: 30px;
  }
}

.js-common-wrap .js-common-content {
  box-shadow: none;
  margin: 0;
}
</style>
