var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "rating-root",
      attrs: {
        title: null,
        visible: _vm.dialogVisible,
        width: "30%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-steps",
        { attrs: { active: _vm.active, "finish-status": "success" } },
        [
          _c("el-step", { attrs: { title: "选择评分标准" } }),
          _c("el-step", { attrs: { title: "开始打分" } }),
          _c("el-step", { attrs: { title: "完成" } }),
        ],
        1
      ),
      _c("div", { staticClass: "main-root" }, [
        _vm.active === 0
          ? _c(
              "div",
              { staticClass: "step-one" },
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "评分标准" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择评分标准",
                              size: "mini",
                            },
                            model: {
                              value: _vm.ratingType,
                              callback: function ($$v) {
                                _vm.ratingType = $$v
                              },
                              expression: "ratingType",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.active === 1
          ? _c(
              "div",
              { staticClass: "step-one" },
              [
                _c(
                  "div",
                  { staticClass: "form-item" },
                  [
                    _c("label", { attrs: { for: "selectedUsers" } }, [
                      _vm._v("要打分的用户"),
                    ]),
                    _vm.type === "standard"
                      ? _c(
                          "el-select",
                          {
                            staticClass: "selectedUsers",
                            attrs: {
                              placeholder: "选择要打分的用户",
                              size: "mini",
                              multiple: "",
                              "value-key": "createdUserId",
                            },
                            model: {
                              value: _vm.selectedUsers,
                              callback: function ($$v) {
                                _vm.selectedUsers = $$v
                              },
                              expression: "selectedUsers",
                            },
                          },
                          _vm._l(_vm.usersList, function (item) {
                            return _c("el-option", {
                              key: item.createdUserId,
                              attrs: { label: item.createdName, value: item },
                            })
                          }),
                          1
                        )
                      : _c(
                          "el-select",
                          {
                            staticClass: "selectedUsers",
                            attrs: {
                              placeholder: "选择要打分的用户",
                              size: "mini",
                              multiple: "",
                              "value-key": "userId",
                            },
                            model: {
                              value: _vm.selectedUsers,
                              callback: function ($$v) {
                                _vm.selectedUsers = $$v
                              },
                              expression: "selectedUsers",
                            },
                          },
                          _vm._l(_vm.usersList, function (item) {
                            return _c("el-option", {
                              key: item.userId,
                              attrs: { label: item.realName, value: item },
                            })
                          }),
                          1
                        ),
                  ],
                  1
                ),
                _vm._l(_vm.selectedUsers, function (user, index) {
                  return _c(
                    "div",
                    { key: user.id, staticClass: "form-item" },
                    [
                      _vm.type === "standard"
                        ? _c("label", { attrs: { for: "selectedUsers" } }, [
                            _vm._v("给" + _vm._s(user.createdName) + "评分"),
                          ])
                        : _c("label", { attrs: { for: "selectedUsers" } }, [
                            _vm._v("给" + _vm._s(user.realName) + "评分"),
                          ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px" },
                          attrs: {
                            placeholder:
                              "请选择评分标准" +
                              _vm.ratingData.origin.description,
                            size: "mini",
                          },
                          model: {
                            value: _vm.ratingData.users[index].grade,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ratingData.users[index],
                                "grade",
                                $$v
                              )
                            },
                            expression: "ratingData.users[index].grade",
                          },
                        },
                        _vm._l(_vm.ratingData.record, function (val, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: val, value: val },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm.active === 2
        ? _c("div", { staticClass: "step-one" }, [
            _c("p", [_vm._v("恭喜，完成了打分~！")]),
          ])
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.active !== 2
            ? _c(
                "div",
                { staticClass: "steps-control" },
                [
                  _vm.active !== 0
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "12px" },
                          on: { click: _vm.prev },
                        },
                        [_vm._v("上一步")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      on: { click: _vm.next },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              )
            : _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "12px" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("完成")]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }